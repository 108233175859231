
export default function newUsersReducer(state, action) {
    switch (action.type) {
      case "FETCH_REQUEST":
      case "FETCH_DETAILS_REQUEST":
      case "ADD_REQUEST":
      case "UPDATE_DETAILS_REQUEST":
        return { ...state, loading: true };
      case "UPDATE_REQUEST":
        return { ...state, loadingUpdate: true };
  
        // used this only

      case "FETCH_SUCCESS":
        return {
          ...state,
          newUsers: action.payload.newUsers,
          newUsersCount: action.payload.newUsersCount,
          loading: false,
        };
        
        //

      case "FETCH_DETAILS_SUCCESS":
        return {
          ...state,
          loading: false,
          newUser: action.payload.newUser
        };
      case "ADD_SUCCESS":
        return { ...state, loading: false, success: true };
      case "UPDATE_DETAILS_SUCCESS":
        return {
          ...state,
          loading: false,
          newUser: action.payload.newUser,
          newUsers: action.payload.newUsers
        };
      case "UPDATE_SUCCESS":
        return { ...state, loadingUpdate: false, success: true };
  
      case "DELETE_SUCCESS":
        const deletednewUserId = action.payload;
        const updatednewUsers = state.newUsers.filter(newUser => newUser._id !== deletednewUserId);
        const updatednewUsersCount = state.newUsersCount - 1;
        return {
          ...state,
          newUsers: updatednewUsers,
          newUsersCount: updatednewUsersCount,
          loading: false
        };
  
      case "FETCH_FAIL":
      case "ADD_FAIL":
      case "FETCH_DETAILS_FAIL":
      case "UPDATE_DETAILS_FAIL":
      case "UPDATE_FAIL":
        return { ...state, loading: false, loadingUpdate: false, error: action.payload };
  
      case 'CLEAR_ERROR':
        return { ...state, error: null };
  
      default:
        return state;
    }
  };