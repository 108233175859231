
import axiosInstance from "../../../utils/axiosUtil";
import { getError } from "../../../utils/error";



export const getAllNewUsers = async (dispatch, token, curPage, resultPerPage, query) => {
  let url = `/api/admin/new-users/?keyword=${query}&resultPerPage=${resultPerPage}&currentPage=${curPage}`;
  
  try {
    dispatch({ type: "FETCH_REQUEST" });
    const { data } = await axiosInstance.get(
      url,
      { headers: { Authorization: token } }
    );
    console.log("new users data: ",data);
    dispatch({ type: "FETCH_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "FETCH_FAIL", payload: getError(error) });
  }
};

